body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #c4c3ca;
  background-color: #1f2029;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: rgb(83, 83, 83);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(164, 164, 164);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i {
  cursor: pointer;
}

#account {
  filter: invert(90%) sepia(9%) saturate(1430%) hue-rotate(341deg) brightness(101%) contrast(113%);
  cursor: pointer;
}

#accountWindow {
  position: absolute;
  background-color: #000000d9;
  border: 1px solid gray;
  padding: 10px;
  z-index: 1;
  border-radius: 10px;
  color: inherit;
  transition: all 0.3s;
  width: 150px;
}

#accountWindow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  left: 84%;
  transform: translate(-50%, 0);
}

#myAccountLink, #logoutLink {
  color: white;
  text-decoration: none;
}